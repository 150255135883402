import React from 'react'
import { IntlProvider } from 'react-intl'

import Layout from './layout'

import messages from '../../locale/pl'
import 'intl/locale-data/jsonp/pl'


const LayoutPL = ({ baseUrl="/", children }) => <IntlProvider locale="pl" messages={messages}>
  <Layout baseUrl={baseUrl}>
    {children}
  </Layout>
</IntlProvider>

export default LayoutPL
