const locale = {
  'LANG_PL': 'PL',
  'LANG_EN': 'ENG',


  'HEADER_CONTACT_CALL': 'Zadzwoń',
  'HEADER_CONTACT_EMAIL': 'E-mail',

  'HEADER_LOGO_CAPTION': 'Twoi doradcy biurowi',

  'HEADER_MENU_ABOUT': 'O nas',
  'HEADER_MENU_SERVICES': 'Usługi',
  'HEADER_MENU_TEAM': 'Zespół',
  'HEADER_MENU_OFFICES': 'Znajdź biuro',
  'HEADER_MENU_BLOG': 'Blog',
  'HEADER_MENU_CONTACT': 'Kontakt',
  'HEADER_MENU_OPINIONS': 'Opinie',
  'HEADER_MENU_MEDIA': 'Media',

  'ADVANTAGES_LEARN_MORE': 'Więcej',

  'SERVICES_LEARN_MORE': 'Więcej',

  'OFFICES_CHECK_NOW': 'Sprawdź teraz',

  'CONTACT_FORM_SEND': 'Wyślij',

  'FOOTER_CONTACT_ADDRESS': 'Adres',
  'FOOTER_CONTACT_PHONE': 'Telefon',
  'FOOTER_CONTACT_EMAIL': 'Email',

  'SUBMIT_MODAL_FORM_TEXT': 'Wiadomość została wysłana. Odpowiemy najszybciej jak to możliwe.',
  'SUBMIT_MODAL_FORM_BUTTON_CLOSE': 'Zamknij',

  'MEDIA_H1': 'Dla mediów',
  'MEDIA_H3': 'Kontakt dla prasy'
}

export default locale
